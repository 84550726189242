<template>
  <vue-good-table class="admin-table" :columns="columns" :rows="rows">
    <template slot="table-row" slot-scope="props">
      <div
        v-if="props.column.field == 'SuggestionNumber'"
        class="idea-number-wrapper"
      >
        <a @click.stop="tableDetail(props.row)">{{
          props.formattedRow[props.column.field]
        }}</a>
      </div>
      <div
        v-else-if="
          props.column.field == 'CreatedOnUtc' ||
          props.column.field == 'SuggestionDate'
        "
      >
        {{ props.formattedRow[props.column.field] | newDateFormatterFilter }}
      </div>
      <div v-else-if="props.column.field == 'Problem'">
        <div v-b-tooltip.hover :title="props.formattedRow[props.column.field]">
          {{ props.formattedRow[props.column.field] | truncateFilter }}
        </div>
      </div>
      <div v-else-if="props.column.field == 'SuggestionDetail'">
        <div v-b-tooltip.hover :title="props.formattedRow[props.column.field]">
          {{ props.formattedRow[props.column.field] | truncateFilter }}
        </div>
      </div>
      <div v-else-if="props.column.field == 'SuggestionStatusName'">
        <span
          class="statu-circle"
          :class="[
            {
              'statu-green':
                props.formattedRow[props.column.field] ==
                  'Değerlendiriciye Yönlendirildi' ||
                props.formattedRow[props.column.field] == 'Değerlendirme' ||
                props.formattedRow[props.column.field] ==
                  'Süreç Yönetimi Ekibine Yönlendirildi' ||
                props.formattedRow[props.column.field] == 'Onaylandı' ||
                props.formattedRow[props.column.field] == 'Canlı' ||
                props.formattedRow[props.column.field] == 'Hayata Geçti' ||
                props.formattedRow[props.column.field] ==
                  'Çalışma Hayata Geçti',
            },
            {
              'statu-red':
                props.formattedRow[props.column.field] == 'Red Edildi' ||
                props.formattedRow[props.column.field] == 'Kabul Edilmedi' ||
                props.formattedRow[props.column.field] == 'Silindi' ||
                props.formattedRow[props.column.field] == 'İptal Edildi' ||
                props.formattedRow[props.column.field] ==
                  'Çalışma İptal Edildi',
            },
            {
              'statu-blue':
                props.formattedRow[props.column.field] ==
                  'Dağıtıcıya Yönlendirildi' ||
                props.formattedRow[props.column.field] == 'Ön Değerlendirme' ||
                props.formattedRow[props.column.field] == 'Çalışılıyor' ||
                props.formattedRow[props.column.field] ==
                  'Karar Vericiye Yönlendirildi' ||
                props.formattedRow[props.column.field] == 'MvP/PoC' ||
                props.formattedRow[props.column.field] ==
                  'Invendoya Yönlendirildi' ||
                props.formattedRow[props.column.field] == 'Beklemede' ||
                props.formattedRow[props.column.field] == 'Ertelendi',
            },
          ]"
          :line-clamp="props.formattedRow[props.column.field]"
        >
          {{ props.formattedRow[props.column.field] }}
        </span>
      </div>
      <div
        v-else-if="
          props.column.field == 'action' &&
          (props.formattedRow['SuggestionStatusName'] != 'Kabul Edilmedi' ||
            props.formattedRow['SuggestionStatusName'] != 'Red Edildi')
        "
      >
        <button
          v-if="
            isLayout == 'Admin' &&
            (props.formattedRow['SuggestionStatusName'] != 'Hayata Geçti' &&
              props.formattedRow['SuggestionStatusName'] !=
                'Çalışma Hayata Geçti') &&
                (props.formattedRow['SuggestionStatusName'] != 'İptal Edildi') &&
            ((location == 'otoProduct' &&
              props.formattedRow['SuggestionStatusName'] != 'Çalışılıyor') ||
              location != 'otoProduct')
          "
          class="btn-innovation-radius bg-blue-2 px-4 py-2"
          data-toggle="modal"
          data-target="#modal-idea-detail"
          @click.stop="tableDetail(props.row)"
        >
          Değerlendir
        </button>
        <button
          v-if="
            (props.formattedRow['SuggestionStatusName'] === 'Çalışılıyor' ||
              props.formattedRow['SuggestionStatusName'] === 'Ön Değerlendirme' ||
              props.formattedRow['SuggestionStatusName'] === 'Dağıtıcıya Yönlendirildi') &&
            (isLayout == 'ProductIdea' || isLayout == 'idea')
          "
          class="btn-innovation-radius bg-blue-2 px-4 py-2 mr-1 mb-1 mt-1"
          data-toggle="modal"
          data-target="#modal-idea-detail"
          @click.stop="tableEdit(props.row)"
        >
          Düzenle
        </button>
        <button
          v-if="
            (props.formattedRow['SuggestionStatusName'] === 'Çalışılıyor' ||
              props.formattedRow['SuggestionStatusName'] === 'Ön Değerlendirme'||
              props.formattedRow['SuggestionStatusName'] === 'Dağıtıcıya Yönlendirildi') &&
            (isLayout == 'ProductIdea' ||
              isLayout == 'idea' ||
              isLayout == 'invendo')
          "
          class="btn-innovation-radius bg-red-1 px-4 py-2"
          data-toggle="modal"
          data-target="#modal-idea-detail"
          @click.stop="tableDelete(props.row)"
        >
          Sil
        </button>
      </div>
      <div
        v-else-if="
          props.column.field == 'SuggestionRDUserModelsValueCurrentManager'
        "
      >
        <div v-b-tooltip.hover :title="props.formattedRow[props.column.field]">
          <span
            v-for="(item, key) in props.formattedRow[props.column.field]"
            :key="key"
            >{{ item }}</span
          >
        </div>
      </div>
      <div v-else-if="props.column.field == 'SuggestionRDUserModelsTeamLead'">
        <div v-b-tooltip.hover :title="props.formattedRow[props.column.field]">
          <span
            v-for="(item, key) in props.formattedRow[props.column.field]"
            :key="key"
            >{{ item }}</span
          >
        </div>
      </div>
      <div v-else>
        {{ props.formattedRow[props.column.field] }}
      </div>
    </template>
  </vue-good-table>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import { newDateFormatter, suggestionStatus } from "../../helpers";
export default {
  name: "AdminHeader",
  components: {
    VueGoodTable,
  },
  props: {
    location: {
      type: String,
      default: "evaluation-invendo",
    },
    columns: {
      type: Array,
    },
    rows: {
      type: Array,
    },
    isLayout: {
      type: String,
      default: "Admin",
    },
  },
  filters: {
    newDateFormatterFilter(value) {
      return newDateFormatter(value);
    },
    truncateFilter(value) {
      return value?.length > 120 ? value.slice(0, 20) + " ..." : value;
    },
    statusFilter(value) {
      return suggestionStatus(value);
    },
  },
  methods: {
    tableDetail(row) {
      this.$emit("table-detail", row);
    },
    tableEdit(row) {
      this.$emit("table-edit", row);
    },
    tableDelete(row) {
      this.$emit("table-delete", row);
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/admin/styles/scss/admin-table.scss";
</style>
