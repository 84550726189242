<template>
  <div
    class="row py-3 justify-content-end flex-shrink-0 idea-form"
    id="filter-div"
    style="margin-left: 20px; margin-right: 20px; margin-top: 20px"
  >
    <div id="searchContainer" class="col-12 row m-0 p-0">
      <div class="container mb-3 mw-100">
        <div class="form-group col-12 col-md-12 row">
          <div class="col-12 col-md-6 column-flex mb-3">
            <div class="col-md-4">
              <label for="searchProblemText">Fikir Numarası</label>
            </div>
            <div class="col-md-8">
              <input
                type="text"
                class="form-control"
                id="suggestionNumber"
                name="suggestionNumber"
                v-model="suggestionNumber"
              />
            </div>
          </div>

          <div class="col-12 col-md-6 column-flex">
            <div class="col-md-4">
              <label for="searchFilterStatus">Fikir Sahibi</label>
            </div>
            <div class="col-md-8">
              <multiselect
                v-model="createdUserId"
                tag-placeholder="Add this as new tag"
                placeholder="Tümü"
                label="name"
                track-by="code"
                :options="optionUsers"
                :taggable="true"
                :searchable="true"
                @tag="addTagUser"
                :disabled="isIdea == 'my-idea'"
                selectLabel="Seçmek için tıklayınız"
                selectedLabel="Seçildi"
                deselectLabel="Silmek için tıklayınız"
              ></multiselect>
            </div>
          </div>
          <div class="col-12 col-md-6 column-flex mb-3">
            <div class="col-md-4">
              <label for="drpAccount">Fikir Verilen Firmalar</label>
            </div>
            <div class="col-md-8">
              <select
                class="form-control"
                id="drpAccount"
                name="AccountId"
                title="Tümü"
                placeholder="Tümü"
                v-model="selectedCompany"
              >
                <option
                  v-for="(item, key) in companiesList"
                  :key="key"
                  :value="item.Id"
                >
                  {{ item.Name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 col-md-6 column-flex mb-3">
            <div class="col-md-4">
              <label for="searchProblemText">Anahtar Kelime</label>
            </div>
            <div class="col-md-8">
              <input
                class="form-control"
                id="searchKeywordText"
                name="KeywordText"
                type="text"
                v-model="searchKeyword"
              />
            </div>
          </div>

          <div class="col-12 col-md-6 column-flex mb-3">
            <div class="col-md-4">
              <label for="searchFilterValueCurrent">Değer Akımı</label>
            </div>
            <div class="col-md-8">
              <select
                class="form-control"
                id="searchFilterValueCurrent"
                name="ValueCurrent"
                title="Tümü"
                placeholder="Tümü"
                v-model="valueCurrentId"
              >
                <option value="">Tümü</option>
                <optgroup
                  v-for="item in currenValuelist"
                  :key="item.Name"
                  :label="item.Name"
                >
                  <option
                    v-for="company in item.ValueCurrentDTOs"
                    :key="company.Id"
                    :value="company.Id"
                  >
                    {{ company.Name }}
                  </option>
                </optgroup>
              </select>
            </div>
          </div>

          <div class="col-12 col-md-6 column-flex mb-3 mb-3">
            <div class="col-md-4">
              <label for="searchFilterStart">Tarih Aralığı</label>
            </div>

            <div class="col-md-8">
              <div class="d-flex flex-column flex-md-row date-container">
                <div class="col-12 col-md-6 px-0">
                  <input
                    type="date"
                    class="datepicker form-control"
                    id="searchFilterStart"
                    v-model="startDate"
                  />
                </div>
                <div
                  class="col-12 col-md-6 px-0 pl-md-2 mt-2 mt-md-0 date-container"
                >
                  <input
                    type="date"
                    class="datepicker form-control"
                    id="searchFilterEnd"
                    v-model="endDate"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 column-flex">
            <div class="col-md-4">
              <label for="searchFilterStatus">Statü Seçiniz</label>
            </div>
            <div class="col-md-8">
              <multiselect
                v-model="suggestionStatuses"
                tag-placeholder="Add this as new tag"
                placeholder="Tümü"
                label="name"
                track-by="code"
                :options="statusOptions"
                :multiple="true"
                :taggable="true"
                :searchable="true"
                @tag="addTag"
                selectLabel="Seçmek için tıklayınız"
                selectedLabel="Seçildi"
                deselectLabel="Silmek için tıklayınız"
              ></multiselect>
            </div>
          </div>
          <div
            v-if="isForm == 'projectPool'"
            class="col-12 col-md-6 column-flex"
          >
            <div class="col-md-4">
              <label>Departman</label>
            </div>
            <div class="col-md-8">
              <multiselect
                v-model="departmentGeneral"
                tag-placeholder="Add this as new tag"
                placeholder="Departman seçiniz"
                label=""
                track-by=""
                :options="departmentsData"
                :searchable="true"
                style="padding: 0"
                selectLabel="Seçmek için tıklayınız"
                selectedLabel="Seçildi"
                deselectLabel="Silmek için tıklayınız"
              >
              </multiselect>
            </div>
          </div>
          <div class="col-12 col-md-6 column-flex">
            <div class="col-md-4">
              <label>Bölüm</label>
            </div>
            <div class="col-md-8">
              <multiselect
                v-model="department"
                tag-placeholder="Add this as new tag"
                placeholder="Bölüm seçiniz"
                label=""
                track-by=""
                :options="departmentsData"
                :searchable="true"
                style="padding: 0"
                selectLabel="Seçmek için tıklayınız"
                selectedLabel="Seçildi"
                deselectLabel="Silmek için tıklayınız"
              >
              </multiselect>
            </div>
          </div>
          <div
            v-if="isForm == 'product' || isForm == 'projectPool'"
            class="col-12 col-md-6 column-flex mb-3"
          >
            <div class="col-md-4">
              <label for="title">Proje Adı</label>
            </div>
            <div class="col-md-8">
              <input
                type="text"
                class="form-control"
                id="title"
                name="title"
                v-model="title"
              />
            </div>
          </div>
          <div
            v-if="isForm == 'product' || isForm == 'projectPool'"
            class="col-12 col-md-6 column-flex"
          >
            <div class="col-md-4">
              <label for="txtProjectName">Proje Yılı</label>
            </div>
            <div class="col-md-8">
              <select
                class="form-control"
                data-val="true"
                data-val-number="The field Value must be a number."
                data-val-required="The Value field is required."
                id="drpProjectYear"
                name="ProjectYear.Value"
                v-model="projectYear"
              >
                <option
                  v-for="item in projectYearList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </option>
              </select>
            </div>
          </div>
          <div
            v-if="isForm == 'product' || isForm == 'projectPool'"
            class="col-12 col-md-6 column-flex"
          >
            <div class="col-md-4">
              <label for="searchFilterStatus">Değer Akım Yöneticisi</label>
            </div>
            <div class="col-md-8">
              <multiselect
                v-model="valueCurrentLeader"
                tag-placeholder="Add this as new tag"
                placeholder="Değer Akım Yöneticisi"
                label="Name"
                track-by="Id"
                :options="currenValuelistLeader"
                :searchable="true"
                style="padding: 0"
                selectLabel="Seçmek için tıklayınız"
                selectedLabel="Seçildi"
                deselectLabel="Silmek için tıklayınız"
              >
              </multiselect>
            </div>
          </div>
          <div v-if="isForm == 'idea'" class="col-12 col-md-6 column-flex">
            <div class="col-md-4">
              <label for="searchFilterStatus">Değerlendirici</label>
            </div>
            <div class="col-md-8">
              <multiselect
                v-model="selectEvaluator"
                tag-placeholder="Add this as new tag"
                placeholder="Değerlendirici"
                label="Name"
                track-by="Id"
                :options="evaluatorList"
                :searchable="true"
                style="padding: 0"
                selectLabel="Seçmek için tıklayınız"
                selectedLabel="Seçildi"
                deselectLabel="Silmek için tıklayınız"
              >
              </multiselect>
            </div>
          </div>
          <!-- <div v-if="isForm == 'idea'" class="col-12 col-md-6 column-flex">
            <div class="col-md-4">
              <label for="searchFilterStatus">Karar Verici</label>
            </div>
            <multiselect
              v-model="selectDecider"
              tag-placeholder="Add this as new tag"
              placeholder="Karar verici"
              label="Name"
              track-by="Id"
              :options="deciderList"
              :searchable="true"
              style="padding: 0"
              selectLabel="Seçmek için tıklayınız"
              selectedLabel="Seçildi"
              deselectLabel="Silmek için tıklayınız"
            >
            </multiselect>
          </div> -->
        </div>
      </div>
      <div class="modal-footer w-100 border-0 justify-content-end">
        <button
          @click="searchFilterRemoveValue"
          type="reset"
          class="btn-innovation-modal bg-orange"
        >
          Sıfırla
        </button>
        <button
          @click="searchFilter"
          type="button"
          class="btn-innovation-modal bg-orange"
        >
          Filtrele <span class="icon-arrow-right pl-2"></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Multiselect from "vue-multiselect";
import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
export default {
  name: "IdeaForm",
  data() {
    return {
      optionUsers: [],
      statusOptions: [],
      currenValuelist: [],
      selectedCompany: null,
      suggestionNumber: "",
      valueCurrentId: "",
      suggestionStatuses: [],
      createdUserId: null,
      searchKeyword: "",
      startDate: null,
      endDate: null,
      teamLeader: null,
      optionTeamLeader: [],
      valueCurrentLeader: null,
      currenValuelistLeader: [],
      title: "",
      projectYear: null,
      projectYearList: [
        {
          value: "",
          label: "Proje Yılını Seçiniz...",
        },
        {
          value: "2015",
          label: "2015",
        },
        {
          value: "2016",
          label: "2016",
        },
        {
          value: "2017",
          label: "2017",
        },
        {
          value: "2018",
          label: "2018",
        },
        {
          value: "2019",
          label: "2019",
        },
        {
          value: "2020",
          label: "2020",
        },
        {
          value: "2021",
          label: "2021",
        },
        {
          value: "2022",
          label: "2022",
        },
        {
          value: "2023",
          label: "2023",
        },
        {
          value: "2024",
          label: "2024",
        },
        {
          value: "2025",
          label: "2025",
        },
        {
          value: "2026",
          label: "2026",
        },
      ],
      // selectDecider: "",
      // deciderList: [],
      evaluatorList: [],
      selectEvaluator: null,
      department: "",
      departmentGeneral: "",
      companiesList: [],
    };
  },
  props: {
    location: {
      type: String,
      default: "evaluation-invendo",
    },
    searchText: {
      type: String,
    },
    isIdea: {
      type: String,
    },
    isLayout: {
      type: String,
    },
    isForm: {
      type: String,
    },
    suggestionTypeId: {
      type: String,
    },
  },
  components: {
    Multiselect,
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    getUsers() {
      return this.$store.getters["user/getUsers"];
    },
    getCompanies() {
      return this.$store.getters["suggestion/companiesData"];
    },
    gettersValueCurrents() {
      return this.$store.getters["suggestion/getValueCurrents"];
    },
    departmentsData() {
      return this.$store.getters["suggestionRD/departmentsData"];
    },
    gettersStatusByRoles() {
      return this.$store.getters["suggestion/statusByRoles"];
    },
    statusByRolesInvendo() {
      return this.$store.getters["suggestion/statusByRolesInvendo"];
    },
    statusByRolesProductization() {
      return this.$store.getters["suggestion/statusByRolesProductization"];
    },
  },
  watch: {
    getUsers: {
      handler: function (val) {
        // Return the object that changed
        val.forEach((element) => {
          this.setUsers(element);
        });
        this.selectedUser();
      },
      deep: true,
    },
  },
  async created() {
    if (this.departmentsData.length == 0) {
      await this.$store.dispatch("suggestionRD/getDepartments");
    }
    if (!this.getUsers) {
      await this.$store.dispatch("user/getUsers");
    } else {
      this.getUsers.forEach((element) => {
        this.setUsers(element);
      });
    }
    this.searchKeyword = this.searchText ? this.searchText : "";
    await this.getValueCurrents();
    await this.getStatusByRoles();
    if (!this.getCompanies) await this.$store.dispatch("suggestion/setData");
    this.companiesList = [{ Id: "", Name: "Tümü" }, ...this.getCompanies];
    this.selectedUser();
    this.equalData();
  },
  methods: {
    equalData() {
      this.selectedCompany = this.user.Data.CompanyId;
    },
    async getStatusByRoles() {
      if (this.suggestionTypeId == "1") {
        if (this.gettersStatusByRoles.length == 0) {
          await this.$store.dispatch("suggestion/getStatusByRoles", {
            suggestionTypeId: this.suggestionTypeId,
            companyId: this.user.Data.CompanyId,
          });
        }
        for await (const element of this.gettersStatusByRoles) {
          this.statusOptions.push({
            code: element.StatusId,
            name: element.StatusName,
          });
        }
      } else if (this.suggestionTypeId == "3") {
        if (this.statusByRolesProductization.length == 0) {
          await this.$store.dispatch(
            "suggestion/getStatusByRolesProductization",
            {
              suggestionTypeId: this.suggestionTypeId,
              companyId: this.user.Data.CompanyId,
            }
          );
        }
        for await (const element of this.statusByRolesProductization) {
          this.statusOptions.push({
            code: element.StatusId,
            name: element.StatusName,
          });
        }
      } else if (this.suggestionTypeId == "4") {
        if (this.statusByRolesInvendo.length == 0) {
          await this.$store.dispatch("suggestion/getStatusByRolesInvendo", {
            suggestionTypeId: this.suggestionTypeId,
            companyId: this.user.Data.CompanyId,
          });
        }
        for await (const element of this.statusByRolesProductization) {
          this.statusOptions.push({
            code: element.StatusId,
            name: element.StatusName,
          });
        }
      }
    },
    setUsers(element) {
      this.optionUsers.push({
        name: element.FullName + " (" + element.CompanyName + ")",
        code: element.Id,
      });
      this.optionTeamLeader.push({
        Name: element.FullName + " (" + element.CompanyName + ")",
        Id: element.Id,
      });
      this.currenValuelistLeader.push({
        Name: element.FullName + " (" + element.CompanyName + ")",
        Id: element.Id,
      });
      // this.deciderList.push({
      //   Name: element.FullName + " (" + element.CompanyName + ")",
      //   Id: element.Id,
      // });
      this.evaluatorList.push({
        Name: element.FullName + " (" + element.CompanyName + ")",
        Id: element.Id,
      });
    },
    selectedUser() {
      if (this.isIdea == "my-idea") {
        this.createdUserId = this.optionUsers.filter(
          (u) => u.code == this.user.Data.UserId
        );
      } else {
        this.createdUserId = null;
      }
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.suggestionStatuses.push(tag);
    },
    addTagUser(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.optionUsers.push(tag);
      this.createdUserId = tag;
    },
    addTagTeamLeader(newTag) {
      const tag = {
        Name: newTag,
        Id: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.teamLeader.push(tag);
      this.optionTeamLeader.push(tag);
    },
    addTagCurrentValue(newTag) {
      const tag = {
        Name: newTag,
        Id: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.valueCurrentLeader.push(tag);
      this.currenValuelistLeader.push(tag);
    },
    async valueCurrentsFill() {
      if (this.gettersValueCurrents.length > 0) {
        this.currenValuelist = this.gettersValueCurrents;
      }
    },
    async getValueCurrents() {
      if (this.gettersValueCurrents.length == 0) {
        await this.$store.dispatch("suggestion/getValueCurrentsData");
      }
      await this.valueCurrentsFill();
    },
    searchFilterRemoveValue() {
      this.selectedCompany = this.user.Data.CompanyId;
      this.suggestionNumber = "";
      this.valueCurrentId = "";
      this.suggestionStatuses = [];
      this.createdUserId = null;
      this.searchKeyword = "";
      this.startDate = null;
      this.endDate = null;
      this.selectEvaluator = null;
      this.department = "";
      this.departmentGeneral = "";
      // this.selectDecider = "";
      this.title = null;
      this.projectYear = null;
      this.teamLeader = null;
      this.valueCurrentLeader = null;
      const data = {
        suggestionNumber: this.suggestionNumber,
        companyId: this.selectedCompany,
        valueCurrentId: this.valueCurrentId,
        suggestionStatuses: this.suggestionStatuses,
        searchKeyword: this.searchKeyword,
        startDate: this.startDate,
        endDate: this.endDate,
        suggestionEvaluatorId: this.selectEvaluator,
        // suggestionDeciderId: this.selectDecider.Id,
        department: this.department,
        departmentGeneral: this.departmentGeneral,
        valueCurrentManagerId: this.valueCurrentLeader,
        title: this.title,
        projectYear: this.projectYear,
      };
      this.$emit("clear-search-filter", data);
    },
    searchFilter() {
      let _tmpSuggestionStatusesId = [];
      let data = null;
      this.suggestionStatuses.map((i) => _tmpSuggestionStatusesId.push(i.code));
      const createdUserId = this.createdUserId ? this.createdUserId.code : null;
      data = {
        suggestionNumber: this.suggestionNumber,
        companyId: this.selectedCompany,
        valueCurrentId: this.valueCurrentId,
        suggestionStatuses: _tmpSuggestionStatusesId,
        searchKeyword: this.searchKeyword,
        startDate: this.startDate,
        endDate: this.endDate,
        suggestionEvaluatorId: this.selectEvaluator
          ? this.selectEvaluator.Id
          : null,
        // suggestionDeciderId: this.selectDecider.Id,
        department: this.department,
        departmentGeneral: this.departmentGeneral,
        valueCurrentManagerId: this.valueCurrentLeader
          ? this.valueCurrentLeader?.Id
          : null,
        title: this.title,
        projectYear: this.projectYear,
      };
      data.teamLeaderId = this.teamLeader?.Id ? this.teamLeader?.Id : null
      if (this.isIdea != "my-idea") data.createdUserId = createdUserId;

      this.$store.dispatch("ideas/setFilterIdea", data);
      this.$emit("search-filter", data);
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/admin/styles/scss/idea-form.scss";
</style>
