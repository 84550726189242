<template>
  <div class="idea-header-wrapper">
    <header class="text-center d-flex justify-content-center flex-column align-items-center">
      <h1 style="color:white;">{{title}}</h1>
      <p class="mb-0 mx-3">
        Problem ve ihtiyaçlara yönelik <b>inovatif</b> ve
        <b>iyileştirme</b> önerilerinizi burada listeleyebilirsiniz.
      </p>
      <hr />
      <nav class="bg-transparent" aria-label="breadcrumb">
        <ol class="breadcrumb bg-transparent text-white d-inline-flex p-0 m-0">
          <li class="breadcrumb-item">
            <a @click="$router.push('/')" class="icon-home cursor-pointer"></a>
          </li>
          <li class="breadcrumb-item" aria-current="page">{{title}}</li>
        </ol>
      </nav>
    </header>
  </div>
</template>

<script>
export default {
  name: "IdeaHeader",
  props:{
    isLayout:{
      type: String
    },
    title:{
      type:String
    },
    subTitle:{
      type:String
    }
  }
};
</script>

<style>
  @import '../../assets/admin/styles/css/idea-header.css';
</style>
