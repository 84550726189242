<template>
  <div
    @click="close"
    id="modal-support"
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    style="display: block; background-color: rgba(0, 0, 0, 0.5); margin: 0"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content" ref="modalContent" @click.stop>
        <div class="modal-header bg-orange rounded-0">
          <h5 class="modal-title text-center text-white mx-auto">FİKRİNE DESTEK İSTE</h5>
          <button
            @click="close"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <form>
          <div class="modal-body">
            <div class="d-flex align-items-center multiselectbox-icon-wrapper">
              <div class="multiselect-icon">
                <b-icon icon="person"></b-icon>
              </div>
              <multiselect
                v-model="selectUser"
                tag-placeholder="Add this as new tag"
                placeholder="Davetin gideceği arkadaşının ismini yaz"
                label="name"
                track-by="code"
                :options="optionUsers"
                :taggable="true"
                :searchable="true"
                @tag="addTag"
                style="padding: 0"
                selectLabel="Seçmek için tıklayınız"
                selectedLabel="Seçildi"
                deselectLabel="Silmek için tıklayınız"
              >
              </multiselect>
            </div>
            <div
              id="txtResponseUserError"
              class="form-group invalid-feedback"
              style="margin-top: 0"
              :style="[requiredField.includes('selectUser') ? { display: 'block' } : '']"
            >
              Bu alan boş geçilemez!
            </div>
            <div class="form-group mt-4">
              <textarea
                v-model="message"
                class="form-control long"
                cols="20"
                id="txtSupportMessage"
                name="SupportMessage"
                placeholder="Fikrin için destek mesajını yaz..."
                rows="2"
                validationelement="1"
              ></textarea>
            </div>
            <div
              id="txtSupportMessageError"
              class="form-group invalid-feedback"
              :style="[requiredField.includes('message') ? { display: 'block' } : '']"
            >
              Bu alan boş geçilemez!
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center">
            <button
              type="button"
              @click="addSupport"
              class="btn-innovation-modal bg-orange"
            >
              DESTEK İSTE <span class="icon-arrow-right pl-2"></span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { suggestionService } from "../../../../services";
import { isNil } from "../../../../helpers";
import Multiselect from "vue-multiselect";
import "../../../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
export default {
  name: "SuggestionSupport",
  data() {
    return {
      message: "",
      selectUser: null,
      optionUsers: [],
      requiredField: [],
    };
  },
  props: ["suggestionId"],
  async created() {
    const $body = document.querySelector("body");
    $body.style.overflow = "hidden";
    if (!this.getUsers) {
      await this.$store.dispatch("user/getUsers");
    } else {
      this.getUsers.forEach((element) => {
        this.optionUsers.push({
          name: element.FullName + " (" + element.CompanyName + ")",
          code: element.Id,
        });
      });
    }
  },
  components: {
    Multiselect,
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    getUsers() {
      return this.$store.getters["user/getUsers"];
    },
  },
  watch: {
    getUsers: {
      handler: function (val) {
        // Return the object that changed
        val.forEach((element) => {
          this.optionUsers.push({
            name: element.FullName + " (" + element.CompanyName + ")",
            code: element.Id,
          });
        });
      },
      deep: true,
    },
  },
  methods: {
    close() {
      this.$emit("close", false);
      const $body = document.querySelector("body");
      $body.style.removeProperty("overflow");
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.optionUsers.push(tag);
      this.selectUser = tag;
    },
    async addSupport() {
      this.requiredField = [];
      if (!isNil(this.message) && !isNil(this.selectUser)) {
        const body = {
          SuggestionId: this.suggestionId,
          SupportResponseUserId: this.selectUser.code,
          SupportRequestUser: this.user.Data.UserId,
          SupportMessage: this.message,
        };
        try {
          await suggestionService.fetchCreateSupport(body);
          this.$toast.success("Destek isteği başarıyla tamamlandı.", {
            position: "top",
          });
          this.$store.dispatch("suggestionNewests/deleteNewestsData");
          this.$store.dispatch("suggestionNewests/setData");
          this.close();
        } catch (error) {
          this.$toast.error("Destek istenirken bir hata oluştu.", {
            position: "top",
          });
        }
      } else {
        if (isNil(this.message)) this.requiredField.push("message");
        if (isNil(this.selectUser)) this.requiredField.push("selectUser");
      }
    },
  },
};
</script>

<style>
@import "../../../../assets/styles/scss/suggestion_support.scss";
</style>
