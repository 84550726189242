var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vue-good-table',{staticClass:"admin-table",attrs:{"columns":_vm.columns,"rows":_vm.rows},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'SuggestionNumber')?_c('div',{staticClass:"idea-number-wrapper"},[_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.tableDetail(props.row)}}},[_vm._v(_vm._s(props.formattedRow[props.column.field]))])]):(
        props.column.field == 'CreatedOnUtc' ||
        props.column.field == 'SuggestionDate'
      )?_c('div',[_vm._v(" "+_vm._s(_vm._f("newDateFormatterFilter")(props.formattedRow[props.column.field]))+" ")]):(props.column.field == 'Problem')?_c('div',[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":props.formattedRow[props.column.field]}},[_vm._v(" "+_vm._s(_vm._f("truncateFilter")(props.formattedRow[props.column.field]))+" ")])]):(props.column.field == 'SuggestionDetail')?_c('div',[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":props.formattedRow[props.column.field]}},[_vm._v(" "+_vm._s(_vm._f("truncateFilter")(props.formattedRow[props.column.field]))+" ")])]):(props.column.field == 'SuggestionStatusName')?_c('div',[_c('span',{staticClass:"statu-circle",class:[
          {
            'statu-green':
              props.formattedRow[props.column.field] ==
                'Değerlendiriciye Yönlendirildi' ||
              props.formattedRow[props.column.field] == 'Değerlendirme' ||
              props.formattedRow[props.column.field] ==
                'Süreç Yönetimi Ekibine Yönlendirildi' ||
              props.formattedRow[props.column.field] == 'Onaylandı' ||
              props.formattedRow[props.column.field] == 'Canlı' ||
              props.formattedRow[props.column.field] == 'Hayata Geçti' ||
              props.formattedRow[props.column.field] ==
                'Çalışma Hayata Geçti',
          },
          {
            'statu-red':
              props.formattedRow[props.column.field] == 'Red Edildi' ||
              props.formattedRow[props.column.field] == 'Kabul Edilmedi' ||
              props.formattedRow[props.column.field] == 'Silindi' ||
              props.formattedRow[props.column.field] == 'İptal Edildi' ||
              props.formattedRow[props.column.field] ==
                'Çalışma İptal Edildi',
          },
          {
            'statu-blue':
              props.formattedRow[props.column.field] ==
                'Dağıtıcıya Yönlendirildi' ||
              props.formattedRow[props.column.field] == 'Ön Değerlendirme' ||
              props.formattedRow[props.column.field] == 'Çalışılıyor' ||
              props.formattedRow[props.column.field] ==
                'Karar Vericiye Yönlendirildi' ||
              props.formattedRow[props.column.field] == 'MvP/PoC' ||
              props.formattedRow[props.column.field] ==
                'Invendoya Yönlendirildi' ||
              props.formattedRow[props.column.field] == 'Beklemede' ||
              props.formattedRow[props.column.field] == 'Ertelendi',
          },
        ],attrs:{"line-clamp":props.formattedRow[props.column.field]}},[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]):(
        props.column.field == 'action' &&
        (props.formattedRow['SuggestionStatusName'] != 'Kabul Edilmedi' ||
          props.formattedRow['SuggestionStatusName'] != 'Red Edildi')
      )?_c('div',[(
          _vm.isLayout == 'Admin' &&
          (props.formattedRow['SuggestionStatusName'] != 'Hayata Geçti' &&
            props.formattedRow['SuggestionStatusName'] !=
              'Çalışma Hayata Geçti') &&
              (props.formattedRow['SuggestionStatusName'] != 'İptal Edildi') &&
          ((_vm.location == 'otoProduct' &&
            props.formattedRow['SuggestionStatusName'] != 'Çalışılıyor') ||
            _vm.location != 'otoProduct')
        )?_c('button',{staticClass:"btn-innovation-radius bg-blue-2 px-4 py-2",attrs:{"data-toggle":"modal","data-target":"#modal-idea-detail"},on:{"click":function($event){$event.stopPropagation();return _vm.tableDetail(props.row)}}},[_vm._v(" Değerlendir ")]):_vm._e(),(
          (props.formattedRow['SuggestionStatusName'] === 'Çalışılıyor' ||
            props.formattedRow['SuggestionStatusName'] === 'Ön Değerlendirme' ||
            props.formattedRow['SuggestionStatusName'] === 'Dağıtıcıya Yönlendirildi') &&
          (_vm.isLayout == 'ProductIdea' || _vm.isLayout == 'idea')
        )?_c('button',{staticClass:"btn-innovation-radius bg-blue-2 px-4 py-2 mr-1 mb-1 mt-1",attrs:{"data-toggle":"modal","data-target":"#modal-idea-detail"},on:{"click":function($event){$event.stopPropagation();return _vm.tableEdit(props.row)}}},[_vm._v(" Düzenle ")]):_vm._e(),(
          (props.formattedRow['SuggestionStatusName'] === 'Çalışılıyor' ||
            props.formattedRow['SuggestionStatusName'] === 'Ön Değerlendirme'||
            props.formattedRow['SuggestionStatusName'] === 'Dağıtıcıya Yönlendirildi') &&
          (_vm.isLayout == 'ProductIdea' ||
            _vm.isLayout == 'idea' ||
            _vm.isLayout == 'invendo')
        )?_c('button',{staticClass:"btn-innovation-radius bg-red-1 px-4 py-2",attrs:{"data-toggle":"modal","data-target":"#modal-idea-detail"},on:{"click":function($event){$event.stopPropagation();return _vm.tableDelete(props.row)}}},[_vm._v(" Sil ")]):_vm._e()]):(
        props.column.field == 'SuggestionRDUserModelsValueCurrentManager'
      )?_c('div',[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":props.formattedRow[props.column.field]}},_vm._l((props.formattedRow[props.column.field]),function(item,key){return _c('span',{key:key},[_vm._v(_vm._s(item))])}),0)]):(props.column.field == 'SuggestionRDUserModelsTeamLead')?_c('div',[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":props.formattedRow[props.column.field]}},_vm._l((props.formattedRow[props.column.field]),function(item,key){return _c('span',{key:key},[_vm._v(_vm._s(item))])}),0)]):_c('div',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }